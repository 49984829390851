import React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
	Knobs,
	Example,
	DocPageMeta,
	ContentSection,
	HeaderSection,
	InlineMessage,
	Code,
	ImportExample,
	Header,
} from "../../../../../src/docs";
import image from "../../../../../src/docs/example-img.png";
import { Ul } from "../../text/list/list";
import { Paragraph } from "../../text/paragraph/paragraph";
import { TextContainer } from "../../text/text-container/text-container";
import { Badge } from "../../../../dist";
import { Link, LinkContainer } from "./link";

export const Meta: DocPageMeta = {
	category: "Navigation",
	title: "Link",
	notepad: "https://hackmd.io/lzvQQxlOSt2pJQCQ244Nvg",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Link"
			subTitle="A link enables users to navigate to a different page, page section, or resource (like a downloadable file)."
		/>
		<ContentSection>
			<TextContainer article>
				<ImportExample component={Link} />
				<InlineMessage variant="warning">
					<Paragraph>
						If your link answers the question "What can I do?", instead of "Where can I go?", use
						the <GatsbyLink to="/lib/components/Actions and controls/Button">button</GatsbyLink>{" "}
						component instead. Its <Code>href</Code> property enables it to behave like a link, but
						look like a button.
					</Paragraph>
				</InlineMessage>
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					Links provide a way for users to move between different parts of the application or access
					external resources. They should be used to enhance navigation and provide access to
					relevant information.
				</Paragraph>
				<Paragraph>
					Need to change the style of a link? You can easily customize the appearance of links using
					the properties described in the following sections.
				</Paragraph>
				<Header.H3>Basic usage</Header.H3>
				<Paragraph>
					Use the default variation for standalone links, providing a clear visual indication of
					interactivity. It features an arrow to indicate that it's a link without relying on color
					alone, ensuring proper communication to color-blind users.
				</Paragraph>
			</TextContainer>
			<Example fn={defaultExample} />
			<TextContainer article>
				<Header.H3>Size</Header.H3>
				<Paragraph>
					Use this property to adjust how attention-grabbing a standalone link should be.
				</Paragraph>
			</TextContainer>
			<Example fn={sizeExample} />
			<TextContainer article>
				<Header.H3>Inline</Header.H3>
				<Paragraph>
					Use within sentences or paragraphs, maintaining the flow of text while providing clear
					navigation. It features an underline, instead of an arrow, to indicate without color that
					it is a link. This variant inherits its size from the text it's part of. To avoid
					localization problems, do not use a placeholder for the link text.
				</Paragraph>
			</TextContainer>
			<Example fn={inlineExample} />
			<TextContainer article>
				<Header.H3>Unstyled</Header.H3>
				<Paragraph>
					Use for interface elements that need to behave like a link without the standard link
					styling. Its custom styling and the UI context in which it's used should make clear that
					it's a link and where it links to.
				</Paragraph>
			</TextContainer>
			<Example fn={unstyledExample} />
			<TextContainer article>
				<Header.H3>Open in new tab or window</Header.H3>
				<Paragraph>
					When a link points to a page on another domain, it's generally best to open it in a new
					tab or window (depending on the user's browser setting). This can be achieved by setting
					the <Code>openNew</Code> property to <Code>true</Code>.
				</Paragraph>
			</TextContainer>
			<Example fn={openNewExample} />
			<TextContainer article style={{ marginBottom: "2.5rem" }}>
				<Header.H3>Download linked resource</Header.H3>
				<Paragraph>
					When a link leads to a resource intended to be downloaded rather than displayed in the
					browser the <Code>download</Code> property can be set to <Code>true</Code>. Provide a
					string value to specify the default file name like so:{" "}
					<Code>download="filename.pdf"</Code>. Best practice is to not open a link, intendded to be
					downloaded, in a new tab, so avoid using <Code>openNew</Code> and <Code>download</Code>{" "}
					together.
				</Paragraph>
			</TextContainer>
			<TextContainer article>
				<Header.H3>Link container and Link text</Header.H3>
				<Paragraph>
					If you want some of the content to not have the link text styling but still be part of the
					link.
				</Paragraph>
			</TextContainer>
			<Example fn={linkContainerExample} />
			<TextContainer article>
				<Header.H2>Properties</Header.H2>
			</TextContainer>
			<Knobs component={Link} initialProps={{ href: "https://www.google.com", children: "Link" }} />
			<TextContainer article>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Ul
						items={[
							<>
								<b>Purpose</b>: Use links for navigation within the application, to external
								resources, to downloadable files, or to provide contact information.
							</>,
							<>
								<b>Variants</b>:
								<Ul
									items={[
										<>
											Use the <b>default</b> variant for standalone links that are presented by
											themselves.
										</>,
										<>
											Use the <b>inline</b> variant for links within sentences or paragraphs.
										</>,
										<>
											Use the <b>unstyled</b> variant when interface elements, like images, need to
											function as links but should not be styled like a typical link.
										</>,
									]}
								/>
							</>,
							<>
								<b>Visual clarity</b>:
								<Ul
									items={[
										<>
											<b>Distinguish links</b>: Links have underlines added automatically to
											distinguish them from surrounding text. Ensure sufficient color contrast is
											used, especially when links are near other interactive elements.
										</>,
										<>
											<b>Interactive feedback</b>: The <Code>:focus</Code> and <Code>:active</Code>{" "}
											states are automatically applied to links to provide visual feedback for
											keyboard and mouse users.
										</>,
										<>
											<b>Visited states</b>: Use a distinct visual style for visited links to help
											users recognize previously accessed pages.
										</>,
									]}
								/>
							</>,
							<>
								<b>Meaningful labels</b>:
								<Ul
									items={[
										<>
											Use concise, descriptive link text that clearly communicates the link's
											purpose or destination.
										</>,
										<>Avoid generic link text like "click here" or "learn more".</>,
										<>When linking to downloads, specify the file type and size.</>,
									]}
								/>
							</>,
							<>
								<b>Context</b>: Provide sufficient context so users understand where the link leads
								or what action it performs.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>
								<b>The link needs to perform an action</b>: Use buttons for actions like "Submit,"
								"Download," "Cancel," or other interactive elements that trigger functionality
								within the current page. Links are primarily for navigation.
							</>,
							<>
								<b>The user needs to toggle between different views or states</b>: Use tabs, content
								switcher, or toggle switches for these interactions, as they provide clearer visual
								cues and affordances for those actions.
							</>,
							<>
								<b>Repeating links</b>: Avoid excessive or unnecessary links on a page or within a
								section of text, especially if they lead to the same destination. This can clutter
								the interface and make it harder for users to find relevant information.
							</>,
							<>
								<b>Linking to page titles/urls from a table</b>: If you need to display a title and
								and URL of a page in a table for example, please use{" "}
								<GatsbyLink to="/lab/components/Text/Title Url">Title Url</GatsbyLink>.
							</>,
							<>
								<b>The link needs to look like a button</b>: If the visual style of a button is
								required, use the button component and configure its properties to function as a
								link. However, only do this when the primary purpose is to navigate to a new
								location ("Where can I go?"), not to perform an action ("What can I do?").
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Ul
						items={[
							<>
								<b>Color contrast</b>: Ensure sufficient color contrast between link text and
								surrounding text.
							</>,
							<>
								<b>Descriptive text</b>: Use link text that clearly describes the destination. Avoid
								generic phrases like "click here" or "learn more".
							</>,
							<>
								<Code>aria-label</Code>: Provide an <Code>aria-label</Code> for links with icons or
								images to provide a text alternative for screen readers.
							</>,
							<>
								<b>Keyboard navigation</b>: Ensure links are easily navigable using the keyboard
								(using the Tab key).
							</>,
							<>
								<b>New windows</b>:
								<Ul
									items={[
										<>
											Open links in a new window or tab only when necessary (e.g., for external
											websites, downloads, or to avoid interrupting a workflow).
										</>,
										<>
											Use the <a href="#open-in-new-tab-or-window">Open new tab or window</a>{" "}
											variant to open links in a new tab and inform users by adding "(opens in new
											tab)" after the link text.
										</>,
										<>
											Alternatively, for complex situations, consider using a Tooltip with the text
											"Opens in new tab" alongside an icon-only button to indicate that the link
											will open in a new window. This approach can provide a more noticeable cue for
											users.
										</>,
									]}
								/>
							</>,
							<>
								<b>Minimum size</b>: Make link text easily clickable with a font size of at least
								12px. Interactive elements should be at least 24×24 pixels in size, or have 24px of
								spacing around them for better usability on touch devices.
							</>,
						]}
					/>
					<Paragraph>
						The component’s implementation ensures that it automatically follows these guidelines:
					</Paragraph>
					<Ul
						items={[
							<>
								Pressing the <Code>Enter</Code> key executes the link and moves focus to the link
								target.
							</>,
							<>
								Add <Code>role=“link”</Code> to the element containing the link.
							</>,
						]}
					/>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							<>
								<b>Clarity</b>:
								<Ul
									items={[
										<>
											Use clear and concise language that accurately describes the link's purpose or
											destination.
										</>,
										<>Avoid ambiguity or jargon.</>,
									]}
								/>
							</>,
							<>
								<b>Specificity</b>:
								<Ul
									items={[
										<>
											<b>Clear destinations</b>: Use link text that clearly indicates the link's
											destination.
										</>,
										<>
											<b>Page titles as links</b>: When linking to a page within the application,
											use the page title as the link text (e.g., "Integrations Overview" instead of
											"See an overview of your integrations").
										</>,
										<>
											<b>Link specific words</b>:
											<Ul
												items={[
													<>
														<b>In sentences</b>: Link only the key words needed to understand the
														destination. Avoid linking entire sentences. For example, instead of
														"Click here to read the documentation", use "Read the documentation".
													</>,
													<>
														<b>For standalone links</b>: Link the whole phrase, such as "Download
														the report".
													</>,
												]}
											/>
										</>,
										<>
											<b>Front-load keywords</b>: Place the most important information at the
											beginning of the link text.
										</>,
										<>
											<b>External links</b>: Clearly indicate when a link leads to an external
											website (e.g., "You can learn more about HTTP status codes in our Help
											Center.").
										</>,
										<>
											<b>Sentence case</b>: Use sentence case for link text.
										</>,
										<>
											<b>Punctuation</b>: Avoid punctuation in link text, except for question marks
											when phrasing a link as a question (e.g., "Forgot your password?").
										</>,
										<>
											<b>No URLs as labels</b>: Don't use a URL as link text, unless it's a broken
											link that needs to be displayed. If showing a link in a table, use{" "}
											<GatsbyLink to="/lab/components/Text/Title Url">Title Url</GatsbyLink>.
										</>,
									]}
								/>
							</>,
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const defaultExample = () => (
	<Link href="https://www.google.com">Medium standalone link (default)</Link>
);

const sizeExample = () => (
	<>
		<Link href="https://www.google.com" size="small">
			Small standalone link
		</Link>
		<br />
		<Link href="https://www.google.com" size="medium">
			Medium standalone link (default)
		</Link>
		<br />
		<Link href="https://www.google.com" size="large">
			Large standalone link
		</Link>
		<br />
	</>
);

const inlineExample = () => (
	<Paragraph>
		This is an example of an{" "}
		<Link href="https://www.google.com" inlineInText>
			inline link
		</Link>
		, which is part of a sentence.
	</Paragraph>
);

/** @ignore due to image reference */
const unstyledExample = () => (
	<Link href="https://www.google.com" unstyled>
		<img src={image} alt="some image with link" height="40" />
	</Link>
);

const openNewExample = () => (
	<Link href="https://www.google.com" openNew>
		Medium standalone link (default)
	</Link>
);

const linkContainerExample = () => (
	<LinkContainer href="https://www.google.com" openNew>
		<Badge>Pre:</Badge> <LinkContainer.LinkText>Styled link text</LinkContainer.LinkText>
	</LinkContainer>
);
